import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/main.scss';
import { App } from './App';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const firebaseConfig = {
    apiKey: 'AIzaSyClgHTt9SQEtOSKoh2M2wMJRJVxnFvfzrM',
    authDomain: 'gux-saas-app.firebaseapp.com',
    projectId: 'gux-saas-app',
    storageBucket: 'gux-saas-app.appspot.com',
    messagingSenderId: '447686938895',
    appId: '1:447686938895:web:58f8215df79370a19f6e7a',
};

initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// serviceWorker.unregister();
