import React from 'react';
import './ModalUsPerson.scss';
import { Modal, ModalBody } from 'reactstrap';
import Imagenes from '../../components/Img/Index';

export const ModalUsPerson = ({ show, setShow }: any) => {
    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="lg" centered>
            <ModalBody className="p-0 ">
                <div className="container modal-us-person">
                    <div className="header-bg row d-flex justify-content-center modal-us-person-header">
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 d-flex justify-content-center align-center p-4">
                            {/* <img
                                className="logo-us-person"
                                style={{ width: '100%' }}
                                src={Imagenes.HomeInitial}
                                alt="avante-logo"
                            /> */}
                        </div>
                        <div className="col-9"></div>
                    </div>
                    <div className="row p-0 m-0 d-flex justify-content-center align-center ">
                        <div className="col-8 d-flex justify-content-center align-center my-3">
                            <span className="modal-us-person-title">
                                Sí Ud. es US person debe leer el siguiente texto.
                            </span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 modal-us-person-content">
                                <span>
                                    Son US person los ciudadanos de Estados Unidos, residentes de Estados Unidos., si
                                    durante los últimos 12 meses has residido más de 183 días en Estados Unidos (no se
                                    consideran estudiantes, profesores, deportistas, diplomáticos, indocumentados o en
                                    otra actividad de representación oficial gubernamental) o los titular de una Green
                                    Card.
                                </span>
                                <br />
                                <br />
                                <span>
                                    Si eres una U.S. person, por la Ley de Cumplimiento Fiscal de Cuentas en el
                                    Extranjero FATCA , tenemos la obligación de reportar anualmente al Servicio de
                                    Impuestos Internos de EE.UU. (IRS) que eres nuestro cliente e informar sus activos
                                    que excedan los USD 50,000 Le recomendamos que revise sus obligaciones con el IRS
                                    para que no lo sancionen. Los contribuyentes estadounidenses que tienen activos
                                    financieros fuera de los Estados Unidos deben reportarlos al IRS en el formulario{' '}
                                    <a
                                        className="linkss"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.irs.gov/forms-pubs/about-form-8938"
                                    >
                                        <b>Form 8938, Statement of Specified Foreign Financial Assets.</b> (About Form
                                        8938, Statement of Specified Foreign Financial Assets | Internal Revenue Service
                                        ( irs.gov))
                                    </a>
                                </span>
                                <br />
                                <br />
                                <span>
                                    En general, el valor agregado de estos activos debe exceder de los USD 50,000 (en
                                    moneda nacional). No informarlos puede resultar en una multa de USD 10,000, que
                                    después de la notificación del IRS se puede transformar en otra multa de USD 50,000
                                    por incumplimiento continuo. Si quiere mayor información se puede ver la{' '}
                                    <b>página oficial del IRS</b> (
                                    <a
                                        className="linkss"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.irs.gov/es"
                                    >
                                        www.irs.gov/es
                                    </a>
                                    )
                                </span>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="ol-lg-3 col-md-4 col-sm-3 col-xs-3">
                                <div
                                    className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                    onClick={() => setShow(!show)}
                                >
                                    Ok, gracias.
                                </div>
                            </div>
                            <div className="col-9"></div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
