import React from 'react';
import { useDispatch } from 'react-redux';
import { addTestNatural } from '../../redux/reducers/naturalForm';
import { addTestJuridica } from '../../redux/reducers/juridicaForm';
import './Pregunta.scss';
const Pregunta = ({ pregunta, setPuntaje, setPreguntaActual }: any) => {
    const dispatch = useDispatch();
    return (
        <div className="row pregunta-container">
            <div className="col-lg-12">
                <div className="row pregunta-wrapper">
                    <div className="col-lg-12 pregunta-box">
                        <span className="pregunta-text">{pregunta.pregunta}</span>
                    </div>
                    {pregunta.respuestas.map((resp: any, index: any) => {
                        return (
                            <div
                                className="col-lg-12 respuesta-box"
                                key={index}
                                onClick={() => {
                                    setPuntaje((prevState: any) => prevState + resp.puntaje);
                                    dispatch(
                                        addTestNatural({ pregunta: pregunta.pregunta, respuesta: resp.respuesta })
                                    );
                                    dispatch(
                                        addTestJuridica({ pregunta: pregunta.pregunta, respuesta: resp.respuesta })
                                    );
                                    setPreguntaActual((prevState: any) => prevState + 1);
                                }}
                            >
                                <span className="respuesta-text">{resp.respuesta}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Pregunta;
