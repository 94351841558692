import React from 'react';
import './Final.scss';

import 'antd/dist/antd.css';

import Imagenes from '../../components/Img/Index';

export const Final = () => {
    return (
        <>
            <div transition-style="in:square:bottom-right" className="bg-gray-home ">
                <div className="top-left-box"></div>
                <div className="bottom-rigth-box"></div>
                <div className="home-page-container">
                    <div className="d-flex flex-column justify-content-between  avante-height-container">
                        <div className="h-75 d-flex align-items-center ">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-6 mb-2">
                                    <h1 className="avante-title-final">Gracias por confiar en nosotros</h1>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-6 d-flex justify-content-center align-items-center ">
                                    {/* <img
                                        className="img-final"
                                       
                                        src={Imagenes.HomeInitial}
                                        alt="avante-logo"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
