import React from 'react';
import { useHistory } from 'react-router-dom';
import './Home.scss';
import { Popover } from 'antd';
import 'antd/dist/antd.css';

import Imagenes from '../../components/Img/Index';
const popoverNatural = (
    <div>
        <p>Si inviertes directamente, y no por medio de una sociedad eres persona natural.</p>
    </div>
);
const popoverJuridica = (
    <div>
        <p>
            Si inviertes por medio de cualquier tipo de sociedad, corporación, o fundación, eres una persona jurídica.
        </p>
    </div>
);
export const Home = () => {
    const history = useHistory();

    return (
        <>
            <div className="bg-gray-home ">
                <div className="top-left-box"></div>
                <div className="bottom-rigth-box"></div>
                <div className="home-page-container">
                    <div className="d-flex flex-column justify-content-between  avante-height-container">
                        <div className="h-75 d-flex align-items-center ">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-11 d-flex justify-content-center align-items-center img-box">
                                    <img src={Imagenes.HomeInitial} alt="avante-logo" />
                                </div>
                                <div className="col-lg-6 col-md-6 ">
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <h1 className="avante-title">Onboarding Financiero - GUX Technologies</h1>
                                        </div>
                                        <div className="col-lg-12 avante-subtitle-box">
                                            <p className="avante-subtitle pl-1">
                                                Por favor, contesta algunas preguntas para
                                                <br />
                                                identificarte como cliente.
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-5 col-lg-6 col-md-6 col-xs-6  mb-2">
                                                    <div
                                                        className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                                        onClick={() => {
                                                            history.push('/cuestionario/natural');
                                                        }}
                                                    >
                                                        Persona Natural
                                                    </div>
                                                </div>
                                                <div className="col-xl-5 col-lg-6 col-md-6 col-xs-6 mb-2">
                                                    <div
                                                        className="btn btn-primary btn-avante-primary-white btn-home d-flex align-items-center justify-content-center"
                                                        onClick={() => {
                                                            history.push('/cuestionario/juridica');
                                                        }}
                                                    >
                                                        Persona Jurídica
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-5 col-lg-6 col-md-6  mb-2 popover-trigger d-flex justify-content-center align-items-center">
                                                    <Popover
                                                        placement="bottom"
                                                        trigger="click"
                                                        content={popoverNatural}
                                                        overlayStyle={{
                                                            width: '300px',
                                                        }}
                                                    >
                                                        <span className="text-center cursor-pointer">
                                                            ¿Quién es una persona natural?
                                                        </span>
                                                    </Popover>
                                                </div>
                                                <div className="col-xl-5 col-lg-6 col-md-6 mb-2 popover-trigger d-flex justify-content-center align-items-center">
                                                    <Popover
                                                        placement="bottom"
                                                        trigger="click"
                                                        content={popoverJuridica}
                                                        overlayStyle={{
                                                            width: '300px',
                                                        }}
                                                    >
                                                        <span className="text-center cursor-pointer">
                                                            ¿Quién es una persona jurídica?
                                                        </span>
                                                    </Popover>
                                                </div>
                                                <div className="col-xl-2 col-lg-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 home-footer">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-4">
                                        <img
                                            src={Imagenes.DirectionInitial}
                                            className="imgdirection"
                                            alt="avante-logo-secondary"
                                        />
                                        <p className="textdirection">
                                            Chile
                                            <br />
                                            Av El Bosque Norte 0226 oficina 402, Las Condes
                                            <br /> +56 9 5157 9044 - contacto@gux.tech
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
